import request from '@/utils/request'

export function getMilestoneDetail(params) {
    return request({
        url: `/milestone/getById`,
        method: 'GET',
        params
    })
}









