<template>
  <div class="detail-page">
    <div class="handle-area">
      <a-button class="width-120" type="danger" icon="delete" @click="delMilestone" v-has-permission="['deleteMilestone']">删除</a-button>
      <a-button class="margin-l20 width-120" type="" icon="delete" @click="$router.go(-1)">返回</a-button>
    </div>
    <a-row :gutter="10">
      <a-col span="12">
        <a-card title="基本信息" :bodyStyle="{'height': '500px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="infoSubmit" v-has-permission="['editMilestone']">保存</a-button>
          </div>
          <div>
            <a-form-model
                ref="infoForm"
                labelAlign="right"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 17 }"
                :model="detail"
                :rules="infoRules"
                :hideRequiredMark="true"
            >
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="里程碑名称" prop="milestoneName">
                    <a-input placeholder="请输入里程碑名称" v-model="detail.milestoneName" :disabled="!canModify" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="里程碑截止日期" prop="closingDate">
                    <a-date-picker
                        :value="detail.closingDate ? moment(detail.closingDate) : null"
                        @change="(date, dateStr) => dateChange(date, dateStr, 'closingDate')"
                        style="width: 100%"
                        :disabled="!canModify"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="里程碑状态" prop="milestoneStatus">
                    <a-select v-model="detail.milestoneStatus" placeholder="请选择里程碑状态" :disabled="!canModify">
                      <a-select-option :value="0">未开始</a-select-option>
                      <a-select-option :value="1">进行中</a-select-option>
                      <a-select-option :value="2">已完成</a-select-option>
                      <a-select-option :value="3">已搁置</a-select-option>
                      <a-select-option :value="4">已取消</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="里程碑进度">
                    <a-progress :percent="detail.milestoneProgressValue || 0" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="里程碑负责人" prop="milestoneLeaderId">
                    <a-select v-model="detail.milestoneLeaderId" placeholder="请选择任务负责人" :disabled="!canModify">
                      <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{item.staffName }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item label="持续时间(天)">
                    <span>计划{{ detail.planDurationDays }}/</span>
                    <span>实际{{ detail.actualDurationDays }}</span>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col span="12">
                  <a-form-model-item label="关联项目" prop="projectId">
                    <a-select v-model="detail.projectId" placeholder="请选择关联项目" :disabled="!canModify">
                      <a-select-option :value="item.id" v-for="(item, index) in projectList" :key="item.id">{{item.projectName }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="12">

                </a-col>
              </a-row>
              <a-row class="margin-t10" :gutter="20">
                <a-col span="24" style="padding-left: 30px">
                  <a-form-model-item prop="projectId" :wrapperCol="{ span: 24 }">
                    <div class="">里程碑描述</div>
                    <a-textarea placeholder="请输入里程碑描述" v-model="detail.remark" style="height: 200px" :disabled="!canModify"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card title="里程碑日志" :bodyStyle="{'height': '500px'}">
          <a-table
              :columns="logColumns"
              :data-source="detail.projectLogs"
              :pagination="{pageSize: 6}"
              rowKey="id"
              bordered
          ></a-table>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="margin-t15">
      <a-col span="24">
        <Gantt type="milestone" :id="$route.query.id" v-if="$route.query.id"></Gantt>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getProjectList } from '../../api/project/project'
import { getMilestoneDetail } from '../../api/milestone/milestoneDetail'
import moment from 'moment';
import { createMilestone, deleteMilestone } from '../../api/milestone/milestone'
import { mapState } from 'vuex'
import Gantt from '@/components/gantt/gantt.vue'
import { getStaffList } from '../../api/humanResources/staff'
export default {
  name: 'milestoneDetail',
  components: {
    Gantt
  },
  data () {
    return {
      detail: {
        projectTaskDataDTO: {}
      },
      infoRules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        projectStatus: [
          { required: true, message: '请选择项目状态', trigger: 'change' }
        ],
        projectLevel: [
          { required: true, message: '请选择项目等级', trigger: 'change' }
        ],
        privince: [
          { required: true, message: '请选择项目地区', trigger: 'change' }
        ]
      },
      canModify: true,
      projectList: [],
      memberList: [],
      logColumns: [
        {
          title: '时间',
          dataIndex: 'createTime'
        },
        {
          title: '事件内容',
          dataIndex: 'logContent'
        },
        {
          title: '触发人',
          dataIndex: 'creator'
        }
      ],
    }
  },
  mounted () {
    this.getDetail()
    this.getProject()
    this.getMember()
    this.canModify = this.authority.editMilestone
  },
  methods: {
    moment,
    getDetail () {
      getMilestoneDetail({
        id: this.$route.query.id
      }).then(res => {
        this.detail = res.data
      })
    },
    dateChange (date, dateString, type) {
      this.detail[type] = dateString
    },
    getProject () {
      getProjectList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.projectList = res.data.list
      })
    },
    getMember () {
      // getTaskMember({
      //   ids: [this.id]
      // }).then(res => {
      //   this.memberList = res.data
      // })
      getStaffList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.memberList = res.data.list
      })
    },
    delMilestone () {
      this.$confirm({
        title: '提示',
        content: '确认删除该数据？',
        okText: '确认',
        cancelText: '取消',
        centered: true,
        onOk: ()=> {
          deleteMilestone({
            ids: [this.$route.query.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.$router.go(-1)
          })
        }
      });
    },
    infoSubmit () {
      this.$refs.infoForm.validate(valid => {
        if (valid) {
          createMilestone(this.detail).then(() => {
            this.$message.success('操作成功！')
            this.getDetail()
          })
        }
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
